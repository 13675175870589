<template>
  <div class="table-container">
    <b-table
      :data="organizations"
      :loading="loading"
      striped
      hoverable
      bordered
      paginated
      backend-pagination
      backend-filtering
      backend-sorting
      pagination-size="is-small"
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      @filters-change="filtersChange"
    >
      <b-table-column field="index" label="Index" v-slot="props">
        {{ sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset }}
      </b-table-column>

      <b-table-column field="id" label="ID" sortable v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="type" label="Type" v-slot="props">
        {{ props.row.type }}
      </b-table-column>

      <b-table-column field="name" label="Name" sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>

      <b-table-column field="organization" label="Organization" sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" size="is-small" />
        </template>
        <template v-slot="props">
          <div v-for="organization in props.row.organization" :key="organization.id">
            <router-link :to="{ name: 'organization', params: { id: organization.id } }">
              {{ organization.name }}
            </router-link>
          </div>
        </template>
      </b-table-column>

      <b-table-column field="enabled" label="Enabled" class="enabled" sortable v-slot="props">
        {{ props.row.enabled ? 'yes' : 'no' }}
      </b-table-column>

      <b-table-column field="actions" label="Actions" v-slot="props">
        <router-link :to="{ name: 'integrations-webhook', params: { id: props.row.id } }">
          <b-button size="is-small">
            <b-icon icon="pencil" size="is-small" />
          </b-button>
        </router-link>
      </b-table-column>

      <template slot="bottom-left">
        <b-button tag="router-link" :to="{ name: 'integration-add' }" type="is-success" icon-left="plus-box"
          >Add Integrations</b-button
        >

        <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined style="margin-left: 100px"
          >Refresh data</b-button
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import { isEqual, pickBy, assign } from 'lodash';

export default {
  data() {
    return {
      organizations: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      loading: false,
      offset: 0,
      params: {},
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const limit = this.perPage;
      const offset = (this.page - 1) * this.perPage;

      const params = assign(
        {
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          limit,
          offset,
        },
        this.params
      );

      const res = await this.$http.get('/v1/integrations', { params });

      this.organizations = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.offset = offset;
      this.loading = false;
    },
    filtersChange(filters) {
      const params = pickBy(filters, (v) => v.length > 2);
      if (!isEqual(params, this.params)) {
        this.params = params;
        this.fetch();
      }
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
  },
};
</script>
