<template>
  <section class="section is-main-section">
    <router-view />
  </section>
</template>

<script>

export default {
  name: 'Integrations',
  components: {},
};
</script>
