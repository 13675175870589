<template>
  <div>
    <form v-on:submit.prevent="save" class="column max" id="integration">
      <b-field label="ID" v-if="integration.id" horizontal>{{ integration.id }}</b-field>

      <b-field label="Created at" v-if="integration.created_at" horizontal>{{
        integration.created_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Updated at" v-if="integration.updated_at" horizontal>{{
        integration.updated_at | moment('YYYY.MM.DD H:mm:ss')
      }}</b-field>

      <b-field label="Name" horizontal>
        <b-input v-model="integration.name" required type="text"></b-input>
      </b-field>

      <b-field label="Enabled" horizontal>
        <b-checkbox v-model="integration.enabled">{{ integration.enabled ? 'Yes' : 'No' }}</b-checkbox>
      </b-field>

      <b-field label="Note" horizontal>
        <b-input type="textarea" v-model="integration.note" rows="2"></b-input>
      </b-field>

      <div class="is-divider"></div>

      <b-field label="Organizations" horizontal>
        <b-table :data="organizations">
          <b-table-column field="name" label="Name" width="500" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="action" v-slot="props">
            <a v-on:click="removeOrganization(props.row)">
              <b-icon title="Remove" icon="minus-circle" size="is-mediu" type="is-danger"></b-icon>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template slot="footer">
            <AutocompleteSelect v-model="organization" uri="/v1/organizations"></AutocompleteSelect>
            <b-button @click="addOrganization" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Add"></i> Add
            </b-button>
          </template>
        </b-table>
      </b-field>
      <!--
      <div class="field is-horizontal" v-if="integration.organization">
        <div class="field-label"><label class="label">Group</label></div>
        <div class="field-body">
          <table>
            <tr v-for="group in integration.group" :key="group.id">
              <td>{{ group.name }}</td>
              <td>
                <b-icon
                  title="Remove"
                  icon="minus-circle"
                  size="is-mediu"
                  type="is-danger"
                ></b-icon>
              </td>
            </tr>
            <tr>
              <td>
                <b-select placeholder="Select a name" v-model="group_id">
                  <option
                    v-for="group in groups"
                    v-bind:key="group.id"
                    v-bind:value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </b-select>
              </td>
            </tr>
          </table>
        </div>
      </div> -->

      <div class="is-divider"></div>

      <b-field label="Method" horizontal>
        <b-select placeholder="Select a method" v-model="integration.argument.method">
          <option value="POST">POST</option>
          <option value="GET">GET</option>
          <option value="PUT">PUT</option>
          <option value="PATCH">PATCH</option>
        </b-select>
      </b-field>

      <b-field label="URL Address" horizontal>
        <b-input v-model="integration.argument.url" required type="url"></b-input>
      </b-field>

      <b-field label="Query Parameters" horizontal>
        <b-table :data="integration.argument.params">
          <b-table-column field="name" label="Name" v-slot="props">
            <b-input v-model="props.row.name"></b-input>
          </b-table-column>

          <b-table-column field="value" label="Value" v-slot="props">
            <b-input v-model="props.row.value"></b-input>
          </b-table-column>

          <b-table-column field="action" v-slot="props">
            <a v-on:click="removeParamsItem(props.row)">
              <span class="icon has-text-info">
                <i class="mdi mdi-minus-circle" title="Remove"></i>
              </span>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template slot="footer">
            <b-button @click="addParamsItem" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Remove"></i> Add parameter
            </b-button>
          </template>
        </b-table>
      </b-field>

      <b-field label="HTTP Headers" horizontal>
        <b-table :data="integration.argument.headers">
          <b-table-column field="name" label="Name" v-slot="props">
            <b-autocomplete v-model="props.row.name" :data="headersNames"></b-autocomplete>
          </b-table-column>

          <b-table-column field="value" label="Value" v-slot="props">
            <b-input v-model="props.row.value"></b-input>
          </b-table-column>

          <b-table-column field="action" v-slot="props">
            <a v-on:click="removeHeaderItem(props.row)">
              <span class="icon has-text-info">
                <i class="mdi mdi-minus-circle" title="Remove"></i>
              </span>
            </a>
          </b-table-column>

          <template slot="empty">Empty</template>

          <template slot="footer">
            <b-button @click="addHeaderItem" type="is-info" size="is-small">
              <i class="mdi mdi-plus-circle" title="Remove"></i> Add header
            </b-button>
          </template>
        </b-table>
      </b-field>

      <div v-if="integration.argument.method !== 'GET'">
        <b-field label="Content Type" horizontal>
          <b-select placeholder="Select a Content Type" v-model="integration.argument.content_type">
            <option value="application/json">application/json</option>
            <option value="application/x-www-form-urlencoded">application/x-www-form-urlencoded</option>
            <option value="application/octet-stream">application/octet-stream</option>
          </b-select>
        </b-field>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Payload</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <b-input type="textarea" v-model="integration.argument.payload"></b-input>
              </div>
              <p class="help">
                Leave this field empty if no further
                <a href="http://docs.jsonata.org/simple" target="_blank">JSONata</a>
                transformation is required (the payload will be passed as-is)
              </p>
            </div>
          </div>
        </div>

        <b-field label="" horizontal>
          <b-field label="Original message">
            <b-input type="textarea" v-model="message" rows="8"></b-input>
          </b-field>
          <b-field label="Transformed payload">
            <b-input type="textarea" v-model="payload" rows="8"></b-input>
          </b-field>
        </b-field>
      </div>
      <br />
      <b-field label horizontal>
        <div class="buttons">
          <b-button v-if="isNew" type="is-success" icon-left="plus-box" native-type="submit">Add integration</b-button>
          <template v-else>
            <b-button type="is-success" icon-left="content-save" native-type="submit">Save integration</b-button>
            <b-button type="is-danger" v-on:click="showDeleteDialog" icon-left="trash-can">Delete integration</b-button>
          </template>
        </div>
      </b-field>
    </form>

    <div class="modal is-active" v-if="deleteDialog">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete confirmation</p>
          <button class="delete" aria-label="close" v-on:click="closeDeleteDialog"></button>
        </header>
        <section class="modal-card-body">Do you really want to delete this integration?</section>
        <footer class="modal-card-foot">
          <button class="button is-danger" v-on:click="deleteMe">Delete</button>
          <button class="button" v-on:click="closeDeleteDialog">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import jsonata from 'jsonata';
import { mapActions } from 'vuex';
import Group from '../../models/Group';
import AutocompleteSelect from '../../components/AutocompleteSelect.vue';

export default {
  name: 'integration',
  components: { AutocompleteSelect },
  data() {
    return {
      integration: {
        enabled: true,
        type: 'Webhook',
        organization_id: [],
        argument: {
          url: 'https://',
          method: 'POST',
          params: [],
          headers: [],
          content_type: 'application/json',
          payload: '',
        },
      },
      headersNames: ['Authorization'],
      deleteDialog: false,
      message: null,
      organization: null,
      organizations: [],
    };
  },
  created() {
    this.fetch();
    // if (this.$route.params.groupId) this.fetchMessage();
  },
  computed: {
    isNew() {
      return !this.$route.params.id || this.$route.params.id === 'new';
    },
    messageObject() {
      return JSON.parse(this.message);
    },
    payload() {
      if (this.integration.argument.payload === '') return this.message;
      try {
        const expr = jsonata(this.integration.argument.payload);
        return JSON.stringify(expr.evaluate(this.messageObject), null, 2);
      } catch (error) {
        return `ERROR: ${error.message}`;
      }
    },
  },
  methods: {
    ...mapActions(['fetch_organization']),
    addHeaderItem() {
      this.integration.argument.headers.push({ name: '', value: '' });
    },
    removeHeaderItem(item) {
      const index = this.integration.argument.headers.indexOf(item);
      if (index !== -1) this.integration.argument.headers.splice(index, 1);
    },
    addParamsItem() {
      this.integration.argument.params.push({ name: '', value: '' });
    },
    removeParamsItem(item) {
      const index = this.integration.argument.params.indexOf(item);
      if (index !== -1) this.integration.argument.params.splice(index, 1);
    },
    async updateOrganizations() {
      this.organizations = await Promise.all(this.integration.organization_id.map((id) => this.fetch_organization(id)));
    },
    async fetch() {
      if (this.isNew) return;
      const { data } = await this.$http.get(`/v1/integration/${this.$route.params.id}`);
      this.integration = data;
      await this.updateOrganizations();

      if (this.message === null) {
        this.fetchMessage();
      }
      // this.fetchGroups();
    },
    async fetchGroups() {
      if (!this.integration.organization) return;
      if (!this.integration.organization[0].id) return;
      const { data } = await Group.getAll(this.integration.organization[0].id);
      this.groups = data;
    },
    async fetchMessage() {
      let data;

      if (this.$route.query.messageId) {
        const rest = await this.$http.get(`/v1/message/${this.$route.query.messageId}`);
        data = rest.data;
      } else {
        const params = {
          sort_field: 'created_at',
          sort_order: 'desc',
          limit: 1,
        };

        if (this.$route.query.deviceId) {
          params.device_id = this.$route.query.deviceId;
        } else if (this.$route.query.groupId) {
          params.group_id = this.$route.query.groupId;
        } else {
          return;
        }

        const rest = await this.$http.get('/v1/messages', { params });
        data = rest.data && rest.data.length === 1 ? rest.data[0] : '';
      }

      if (data.confirmed !== undefined) delete data.confirmed;
      if (data.integration) delete data.integration;
      this.message = JSON.stringify(data, null, 2);
    },
    async save() {
      if (this.isNew) {
        const { data } = await this.$http.post('/v1/integrations', this.integration);
        this.integration = data;
        await this.updateOrganizations();
        this.$router.push({ name: 'integrations-webhook', params: { id: data.id } });
        this.$toast.success('Integration was created');
      } else {
        const { data } = await this.$http.put(`/v1/integration/${this.$route.params.id}`, this.integration);
        this.integration = data;
        this.$toast.success('Integration was saved');
      }
    },
    async deleteMe() {
      // if (!this.deleteDialog) return;
      // await integration.delete(this.integration.id);
      // this.closeDeleteDialog();
      // this.$toast.success('integration was deleted');
      // this.$router.push({
      //   name: 'integrations',
      //   params: { groupId: this.integration.group_id },
      // });
    },
    showDeleteDialog(e) {
      e.preventDefault();
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = null;
    },
    async addOrganization() {
      if (!this.organization) return;
      if (this.integration.organization_id.indexOf(this.organization) !== -1) return;
      this.integration.organization_id.push(this.organization);
      this.organizations.push(await this.fetch_organization(this.organization));
      console.log(this.organizations);
      this.organization = null;
    },
    removeOrganization(item) {
      this.integration.organization_id.splice(this.integration.organization_id.indexOf(item.id), 1);
      this.organizations.splice(this.organizations.indexOf(item), 1);
    },
  },
};
</script>
<style>
#integration .table {
  background-color: transparent !important;
}
</style>
