<template>
  <div>
    <!-- <form v-on:submit.prevent="crate" class="column max" id="integration">
      <b-field label="Name" horizontal>
        <b-input v-model="integration.name" required type="text"></b-input>
      </b-field>

      <b-field label="Enabled" horizontal>
        <b-checkbox v-model="integration.enabled">{{
          enabled ? "Yes" : "No"
        }}</b-checkbox>
      </b-field>
    </form> -->

    <router-link :to="{name: 'integrations-new-webhook' }">
      <b-button icon-left="webhook" >Webhook</b-button>
    </router-link>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      integration: {
        name: '',
        enabled: false,
      },
    };
  },
  methods: {
    crate() {

    },
  },
};
</script>
